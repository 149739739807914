import React, { useLayoutEffect, useRef } from "react";
import * as ClientDetailsStyles from "./clientDetails.module.scss";

import ClientDetailsImg from "../../../../assets/images/client-details-cover.png";
import ClientDetailsImg1 from "../../../../assets/images/client-details-1.png";
import ClientDetailsImg2 from "../../../../assets/images/client-details-2.png";
import Avatar from "../../../../assets/images/avatar.png";
import ClientStoriesShortListing from "../ClientStoriesShortListing/ClientStoriesShortListing";

import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";

const ClientStoriesData = [
  {
    title: "Challenging the impossible for DOK32",
    subtitle: "Brand Transformation",
    link: "/client/DOK32",
  },
  {
    title: "Unlocking pure agility for Smartboard",
    subtitle: "Performance Optimization And More ",
    link: "/client/smart-board",
  },
];

const ITFlakesDetails = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimation("client-details-global-wrapper", 1.2);
  }, []);

  return (
    <section
      className={`${ClientDetailsStyles.ClientDetailsWrapper} client-details-global-wrapper`}
    >
      <span className="es-subtitle-text">Business Continuity</span>
      <h1>A new life for IT Flakes</h1>

      <div className={ClientDetailsStyles.clientDetailsCoverImg}>
        <img src={ClientDetailsImg} alt="A new life for IT Flakes" />
      </div>

      <div
        className={`${ClientDetailsStyles.ClientDetailsStory} story-text-wrapper`}
      >
        <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
          <span className="story-text">The story</span>
          <p>
            Our professionals at Endshift helped IT Flakes with disaster
            recovery giving a new life to the brand. Before coming to us, they
            had already tried and tested various professionals in the data
            recovery and business continuity field from across the world.
            Approximately 95 clinics were impacted by the data breach at the
            healthcare firm.
          </p>
        </div>
      </div>

      <div className={ClientDetailsStyles.ClientDetailsAdditional}>
        <div className={ClientDetailsStyles.ClientDetailsGallery}>
          <div>
            <img src={ClientDetailsImg1} alt="" />
          </div>
          <div>
            <img src={ClientDetailsImg2} alt="" />
          </div>
        </div>

        <div className={ClientDetailsStyles.ClientDetailsAdditionalDesc}>
          <div className={ClientDetailsStyles.ClientDetailsStory}>
            <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
              <span>Were we successful or what?</span>
              <p>
                Disaster recovery being one of our fortes, Endshift resolved
                their issue in less than 6 months and recovered 95% of the data.
                IT Flakes continued to grow and excel without implications
                sooner than any tech consultant told them.
              </p>
            </div>
          </div>
          <div className={ClientDetailsStyles.ClientDetailsStory}>
            <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
              <span>What about the long run?</span>
              <p>
                Years later today, IT Flakes has been up and running, catering
                to hundreds of clinics across the MENA region without fail.
                Endshift is future-focused, all our solutions are as well.
              </p>
            </div>
          </div>
          <div className={ClientDetailsStyles.ClientDetailsReview}>
            <div className={ClientDetailsStyles.ClientDetailsReviewContent}>
              <p>
                “I have faced a close to unprecedented situation where the
                Server Hosting company crashed our servers, and cause a data
                corruption to our Database. it happens, but this happened and
                caused many months loss of data for too many clients. backups
                were damaged, and situation was as bleak as it gets.
                <br></br>
                <br></br>
                The only thing that could help was really a commitment and a
                proper communication with a DB Master such like Atif. he spent a
                lot of time with us on this, and was very helpful and
                communicated what we as we went on with the recovery. he was
                such life saver really helped save our data and the company from
                going under.”
              </p>
              <div className={ClientDetailsStyles.ClientDetailsReviewer}>
                <div>
                  <img src={Avatar} alt="Jimmy Kim" />
                </div>
                <div>
                  <h6>Muhammad Hijazi</h6>
                  <span>Founder/CEO of IT Flakes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ClientDetailsStyles.ClientDetailsNext}>
        <span className="es-subtitle-text">Next Projects</span>
        <ClientStoriesShortListing ClientStoriesData={ClientStoriesData} />
      </div>
    </section>
  );
};

export default ITFlakesDetails;
